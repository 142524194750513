import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../components/layout'

const BusinessOpportunitiesPage = () => {
  return (
    <Layout pageTitle="Business Opportunities @ MRC" title="Business Opportunities @ MRC" description="Be it farmers, buyers, traders or investors, everyone stands to gain while associating with MRC Agrotech.">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <StaticImage className="mb-3" src="../images/business-opportunities.jpg" width={1000} height={342} alt="Business Opportunities" />
          <p>Be it farmers, buyers, traders or investors, everyone stands to gain while associating with MRC Agrotech. Being a technology-oriented trading platform, there is something for every major constituent of the market. MRC is a treasure trove of opportunities in commodity trading and contract manufacturing. With frequent opportunities spread across various states, MRC Agrotech is buzzing with activity. Get in touch with the team to know more about the current business opportunities - <a href="/contact-us/#enquiry">Click here</a>.</p>
        </div>
      </div>
    </Layout>
  )
}

export default BusinessOpportunitiesPage
